<template>
  <div class="page-saved-projects page-saved-y3Agd7df1DfxA2">
    <checked-projects-toolbar
      v-if="checkedProjects.length > 0"
    ></checked-projects-toolbar>

    <loading
      :active="$wait.is('deletingFolder') || $wait.is('updatingFolder')"
      :is-full-page="true"
    ></loading>

    <el-breadcrumb
      v-if="!isRootFolder"
      class="mt4 mb3"
      separator-class="el-icon-arrow-right"
    >
      <el-breadcrumb-item
        class="color-base"
        :to="{ path: '/dashboard/saved/all' }"
      >
        <i class="el-icon-arrow-left"></i> Back</el-breadcrumb-item
      >
      <el-breadcrumb-item v-if="folderId !== 'all'">{{
        mapFolderIdByName(folderId)
      }}</el-breadcrumb-item>
    </el-breadcrumb>

    <checked-projects-toolbar
      v-if="checkedProjects.length > 0"
    ></checked-projects-toolbar>

    <el-button
      type="primary"
      v-if="isRootFolder"
      plain
      :icon="
        $wait.is('creatingFolder') ? 'el-icon-loading' : 'el-icon-folder-add'
      "
      @click="toggleFolderPopup(true)"
      class="mt4 btn-add-folder"
    >
      <span class="ml2">Add a new folder</span>
    </el-button>

    <div class="folders-list">
      <masonry
        v-if="isRootFolder"
        class="masonry-layout"
        :cols="4"
        :gutter="30"
      >
        <div
          class="item"
          @click="openSubfolder(folder)"
          v-for="(folder, index) in folders"
          :key="folder._id"
        >
          <div class="item-top">
            <div
              :class="{
                'keep-open': openMenuIndex === index,
              }"
            >
              <span class="folder-name">{{ folder.name }}</span>
              <el-dropdown
                @command="handleFolderMoreMenu"
                class="btn-more"
                trigger="click"
                :ref="folder._id"
              >
                <el-button
                  icon="el-icon-more el-icon-more-folder"
                  type="text"
                  @click.stop="openDropdown(folder._id, index)"
                ></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="['rename', folder]"
                    icon="el-icon-edit"
                    >Rename</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="['delete', folder]"
                    icon="el-icon-delete"
                    >Delete</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </masonry>
    </div>

    <div class="projects-list" v-if="folderId !== 'all'">
      <projects-grid
        :items="projects"
        :showCheckbox="true"
        @handleMoreMenu="handleProjectMoreMenu"
        @projectMenuOpened="projectMenuOpened"
        @setOpenMenuIndex="setOpenMenuIndex"
      ></projects-grid>
    </div>

    <img
      class="img-empty-folder"
      :src="`${publicPath}assets/folders.svg`"
      alt=""
      v-if="folderId !== 'all'"
    />

    <el-dialog
      title="Create a new folder"
      :visible.sync="showPopupCreateFolder"
      :append-to-body="true"
      width="30%"
    >
      <div class="dialog-folder ml3">
        <el-form class="dialog-folder-form" @submit.native.prevent ref="form">
          <el-form-item label="Name">
            <el-input
              @keyup.enter.native="addNewFolder"
              placeholder="Name"
              v-model="folderNewName"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :icon="$wait.is('creatingFolder') ? 'el-icon-loading' : ''"
          @click="addNewFolder"
          >OK</el-button
        >
        <el-button @click="toggleFolderPopup(false)">Cancel</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Update folder"
      :visible.sync="showPopupUpdateFolder"
      :append-to-body="true"
      width="30%"
    >
      <div class="dialog-folder ml3">
        <el-form class="dialog-folder-form" @submit.native.prevent ref="form">
          <el-form-item label="Name">
            <el-input
              placeholder="Name"
              @keyup.enter.native="updateExistingFolder"
              v-model="folderUpdateName"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateExistingFolder">OK</el-button>
        <el-button @click="toggleFolderUpdatePopup(false)">Cancel</el-button>
      </span>
    </el-dialog>

    <popup-rename-project
      :openedMenuProject="openedMenuProject"
      :folderId="folderId"
      @onClosePopupRename="onClosePopupRename"
      v-if="showPopupRenameProject"
    ></popup-rename-project>

    <div v-if="folderId === 'all'" class="saved-projects">
      <loading
        :active="$wait.is('isLoadingProjects')"
        :is-full-page="false"
      ></loading>
      <div class="d-flex mt4 justify-space-between"></div>
      <!-- <div class="search-box">
        <el-input placeholder="Search" v-model="search"></el-input>
      </div> -->

      <div
        class="text-center"
        v-if="projects.length === 0 && !$wait.is('isLoadingProjects')"
      >
        <img
          class="img-saved-projects"
          :src="`${publicPath}assets/chart-boxes.svg`"
          alt=""
        />
        <el-button class="mt4" type="success" @click="createGraphNow"
          >Create a new project</el-button
        >
      </div>

      <div v-else-if="projects.length > 0">
        <projects-grid
          :items="filteredProjects"
          :showCheckbox="true"
          @projectMenuOpened="projectMenuOpened"
          @handleMoreMenu="handleMoreMenu"
          @setOpenMenuIndex="setOpenMenuIndex"
        ></projects-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import projectsGrid from "@/components/dashboard/common/projects-grid.component";
import checkedProjectsToolbar from "../common/checked-projects-toolbar.component";
import popupRenameProject from "../common/popup-rename-project.component";
import { DASHBOARD_MIXIN } from "@/components/common/mixins/dashboard.mixins";

export default {
  mixins: [DASHBOARD_MIXIN],
  components: {
    projectsGrid,
    popupRenameProject,
    checkedProjectsToolbar,
  },
  computed: {
    ...mapActions("projectsModule", [
      "getFolders",
      "createFolder",
      "updateFolder",
      "deleteFolder",
    ]),
    ...mapGetters("projectsModule", ["checkedProjects", "projects", "folders"]),
  },
  data() {
    return {
      openMenuIndex: -1,
      lastOpenMenuIndex: -1,
      filteredProjects: [],
      search: "",
      showPopupCreateFolder: false,
      showPopupUpdateFolder: false,
      folderNewName: "New Folder",
      folderUpdateName: "",
      folderId: "all",
      openedMenuProject: null,
      dropdownFolderId: null,
      isRootFolder: true,
      showPopupRenameProject: false,
    };
  },
  watch: {
    $route() {
      this.initOnLoad();
    },
    projects: function(newProps) {
      this.filteredProjects = this.filterSavedProjects(newProps, this.search);
    },
    search: function(newSearch) {
      this.filteredProjects = this.filterSavedProjects(
        this.projects,
        newSearch
      );
    },
  },
  mounted: async function() {
    this.initOnLoad();
  },
  methods: {
    initOnLoad() {
      this.folderId = this.$route.params.folderId;
      this.filteredProjects = [];
      if (this.folderId === "all") {
        this.isRootFolder = true;
        this.getAllFolders();
        this.$wait.start("isLoadingProjects");
        this.$_dashboardMixin_getProjects({
          folderId: this.folderId,
        });
      } else {
        this.isRootFolder = false;
        this.getProjectsByFolder();
      }
    },
    startDrag(e, project) {
      console.log(e, project);
    },
    createGraphNow() {
      this.$router.push(`/c/new`);
    },
    togglePopupRename(val) {
      this.showPopupRenameProject = val;
    },
    onClosePopupRename() {
      this.togglePopupRename(false);
    },
    handleMoreMenu(command) {
      if (command === "delete") {
        this.$_dashboardMixin_removeProjects([
          this.filteredProjects[this.lastOpenMenuIndex]._id,
        ]);
      } else if (command === "rename") {
        this.togglePopupRename(true);
      }
    },
    filterSavedProjects: function(projects, search) {
      if (search === "") {
        return projects.slice();
      }

      return projects.filter((c) => {
        let exists = false;

        const words = c.file_name.split(" ");
        words.push(c.file_name);
        for (let i = 0; i < words.length; i++) {
          if (
            words[i].toLowerCase().indexOf(search.trim().toLowerCase()) > -1
          ) {
            exists = true;
          }
        }
        return exists;
      });
    },
    mapFolderIdByName(id) {
      if (!this.folders.length) return;
      const folder = this.folders.filter((f) => {
        return f._id === id;
      })[0];

      return folder.name;
    },
    getProjectsByFolder() {
      if (this.$route.params.folderId !== "all") {
        this.$_dashboardMixin_getProjects({
          folderId: this.folderId,
        });
      }
    },
    getAllFolders() {
      this.$wait.start("isLoadingFolders");
      this.$store
        .dispatch("projectsModule/getFolders")
        .then((response) => {
          this.$wait.end("isLoadingFolders");
        })
        .catch((e) => {
          this.$wait.end("isLoadingFolders");

          console.error(e);
        });
    },
    toggleFolderPopup(val) {
      this.showPopupCreateFolder = val;
    },
    toggleFolderUpdatePopup(val) {
      this.showPopupUpdateFolder = val;
      if (val === false) {
        this.dropdownFolderId = null;
      }
    },
    handleFolderMoreMenu(command) {
      if (command[0] === "rename") {
        this.toggleFolderUpdatePopup(true);
        this.dropdownFolderId = command[1]._id;
        this.folderUpdateName = command[1].name;
      } else if (command[0] === "delete") {
        this.removeFolder(command[1]._id);
      }
    },
    handleProjectMoreMenu(command) {
      if (command === "delete") {
        this.$_dashboardMixin_removeProjects([this.openedMenuProject._id]);
      } else if (command === "rename") {
        this.togglePopupProjectRename(true);
      }
    },
    togglePopupProjectRename(val) {
      this.showPopupRenameProject = val;
    },
    projectMenuOpened(openedMenuProject) {
      this.openedMenuProject = openedMenuProject;
    },
    setOpenMenuIndex(index) {
      this.openMenuIndex = index;
    },
    addNewFolder() {
      this.$wait.start("creatingFolder");
      this.$store
        .dispatch("projectsModule/createFolder", {
          name: this.folderNewName,
        })
        .then((res) => {
          this.$wait.end("creatingFolder");
          this.toggleFolderPopup(false);
          this.getAllFolders();
        })
        .catch((e) => {
          console.error(e);
          this.$wait.end("creatingFolder");

          this.$message({
            type: "error",
            message: "There was an error creating a folder. " + e,
          });
        });
    },
    updateExistingFolder() {
      this.$wait.start("updatingFolder");
      this.$store
        .dispatch("projectsModule/updateFolder", {
          id: this.dropdownFolderId,
          data: {
            name: this.folderUpdateName,
          },
        })
        .then((res) => {
          this.$wait.end("updatingFolder");
          this.toggleFolderUpdatePopup(false);
          this.getAllFolders();
        })
        .catch((e) => {
          console.error(e);
          this.$wait.end("updatingFolder");

          this.$message({
            type: "error",
            message: "There was an error updating the folder. " + e,
          });
        });
    },
    removeFolder(folderId) {
      this.$wait.start("deletingFolder");
      this.$store
        .dispatch("projectsModule/deleteFolder", {
          id: folderId,
        })
        .then((res) => {
          this.$wait.end("deletingFolder");
          this.getAllFolders();
        })
        .catch((e) => {
          console.error(e);
          this.$wait.end("deletingFolder");

          this.$message({
            type: "error",
            message:
              "There was an error deleting the folder. " + e.data.message,
          });
        });
    },
    openSubfolder(folder) {
      this.$router.push(`/dashboard/saved/${folder._id}`).catch(() => {});
    },
    openDropdown(ref, index) {
      if (this.openMenuIndex === index) {
        this.openMenuIndex = -1;
        this.$refs[ref][0].hide();
        return;
      } else {
        this.$refs[ref][0].show();
      }
      this.openMenuIndex = index;
    },
  },
};
</script>

<style lang="scss" scope>
@import "saved.component.scss";
</style>

<style lang="scss">
.page-saved-y3Agd7df1DfxA2 {
  .btn-add-folder .el-icon-folder-add:before {
    content: "\e783";
    font-size: 22px;
  }
  .el-breadcrumb__inner a,
  .el-breadcrumb__inner.is-link {
    color: inherit;
  }
  .el-icon-more-folder {
    color: #444;
    padding: 4px;
    transition: 0.2s ease all;
    border-radius: 3px;

    &:hover {
      background: #eee;
      transition: 0.2s ease all;
    }
  }
}
</style>
