<template>
  <div class="d-flex menu-checked-items">
    <div class="toolbar dark">
      <div class="item delete">
        <el-tooltip effect="dark" content="Delete">
          <IcoBin
            @click="$_dashboardMixin_removeProjects(checkedProjects)"
          ></IcoBin>
        </el-tooltip>
      </div>
      <div class="item move-to-folder">
        <el-tooltip effect="dark" content="Move to Folder">
          <IcoFolder @click="togglePopupMoveToFolder(true)"></IcoFolder>
        </el-tooltip>
      </div>
    </div>

    <popup-move-to-folder
      @onCloseMoveToFolderPopup="onCloseMoveToFolderPopup"
      v-if="showPopupMoveToFolder"
    ></popup-move-to-folder>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DASHBOARD_MIXIN } from "@/components/common/mixins/dashboard.mixins";
import IcoBin from "@/components/common/ico/delete-24px.svg";
import IcoFolder from "@/components/common/ico/folder_open-white-18dp.svg";
import popupMoveToFolder from "@/components/dashboard/common/popup-move-to-folder.component";

export default {
  mixins: [DASHBOARD_MIXIN],
  components: {
    IcoBin,
    IcoFolder,
    popupMoveToFolder,
  },
  computed: {
    ...mapGetters("projectsModule", ["checkedProjects"]),
  },
  data() {
    return {
      showPopupMoveToFolder: false,
    };
  },

  methods: {
    togglePopupMoveToFolder(value) {
      this.showPopupMoveToFolder = value;
    },
    onCloseMoveToFolderPopup() {
      this.showPopupMoveToFolder = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.item.move-to-folder {
  svg {
    transform: scale(1.35);
  }
}
</style>
