<template>
  <el-dialog
    title="Move to Folder"
    class="move-to-folder-popup"
    width="30%"
    :visible.sync="folderBoxShown"
    :append-to-body="true"
    :before-close="beforeClose"
    :destroy-on-close="true"
    :center="true"
  >
    <el-form @submit.native.prevent v-if="newFolderBoxShown" class="new-folder">
      <el-form-item class="mb4" label="New Folder">
        <el-input
          placeholder="Enter new folder name"
          v-model="folderNewName"
          @keyup.enter.native="moveToNewFolder"
        ></el-input>
      </el-form-item>
    </el-form>
    <div v-else class="folders-list">
      <el-table
        ref="folderTable"
        :data="folders"
        highlight-current-row
        @current-change="handleCurrentChange"
        style="width: 100%"
      >
        <el-table-column property="name" label=""> </el-table-column>
      </el-table>
    </div>
    <span v-if="newFolderBoxShown">
      <div class="d-flex justify-space-between">
        <div class="left">
          <el-button @click="toggleNewFolder(false)" icon="el-icon-back"
            >Back</el-button
          >
        </div>
        <div class="d-flex right">
          <el-button
            type="primary"
            :disabled="folderNewName === ''"
            @click="moveToNewFolder"
            >Move to New Folder</el-button
          >
          <el-button type="warning" plain @click="beforeClose"
            >Cancel</el-button
          >
        </div>
      </div>
    </span>
    <span v-else slot="footer" class="dialog-footer ">
      <div class="d-flex justify-space-between">
        <div class="left">
          <el-button @click="toggleNewFolder" icon="el-icon-plus"
            >New Folder</el-button
          >
        </div>
        <div class="d-flex right">
          <el-button
            type="primary"
            :disabled="selectedFolderId === null"
            @click="moveToFolder(null)"
            >Move</el-button
          >
          <el-button type="warning" plain @click="beforeClose"
            >Cancel</el-button
          >
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DASHBOARD_MIXIN } from "@/components/common/mixins/dashboard.mixins";

export default {
  mixins: [DASHBOARD_MIXIN],
  props: {
    selectedProject: {
      type: Array,
    },
  },
  data() {
    return {
      newFolderBoxShown: false,
      folderBoxShown: false,
      folderNewName: "",
      selectedFolderId: null,
    };
  },
  computed: {
    ...mapGetters("projectsModule", ["checkedProjects", "folders"]),
    ...mapActions("projectsModule", ["createFolder", "getFolders"]),
  },
  mounted: function() {
    this.folderBoxShown = true;
  },
  methods: {
    handleCurrentChange(val) {
      this.selectedFolderId = val._id;
    },
    addNewFolder() {
      return new Promise((resolve, reject) => {
        this.$wait.start("creatingFolder");
        this.$store
          .dispatch("projectsModule/createFolder", {
            name: this.folderNewName,
          })
          .then((res) => {
            resolve(res.data);
            this.$wait.end("creatingFolder");
          })
          .catch((e) => {
            console.error(e);
            this.$wait.end("creatingFolder");
            reject(e);
            this.$message({
              type: "error",
              message: "There was an error creating a folder. " + e,
            });
          });
      });
    },
    beforeClose: function() {
      this.$emit("onCloseMoveToFolderPopup");
    },
    moveToFolder(folderId) {
      return new Promise((resolve, reject) => {
        const fId = folderId ? folderId : this.selectedFolderId;
        this.$_dashboardMixin_moveProjectsToFolder(
          this.selectedProject ? [this.selectedProject] : this.checkedProjects,
          fId
        )
          .then(() => {
            this.selectedFolderId = null;
            this.toggleDialog(false);
            resolve();

            if (!folderId) {
              // in case when a user is just moving projects across existing folders,
              // i.e., no new folder is created, we redirect here
              this.$router.push(`/dashboard/saved/${fId}`);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    moveToNewFolder() {
      this.addNewFolder()
        .then((res) => {
          this.moveToFolder(res._id)
            .then(() => {
              this.$store
                .dispatch("projectsModule/getFolders")
                .then(() => {
                  // in case when a user moves to new folder,
                  // we redirect after getting all the folder list
                  this.$router.push(`/dashboard/saved/${res._id}`);
                })
                .catch((e) => {
                  //
                });
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    },
    toggleDialog: function(value) {
      this.folderBoxShown = value;
    },
    toggleNewFolder: function(val) {
      this.newFolderBoxShown = val;
    },
  },
};
</script>

<style lang="scss"></style>
