<template>
  <el-checkbox-group v-model="checkedItems">
    <masonry class="masonry-layout" :cols="4" :gutter="30">
      <div
        class="item"
        @dragstart="startDrag($event, project)"
        v-for="(project, index) in items"
        :key="project._id"
      >
        <div class="item-top">
          <img class="gallery-image" :src="project.preview" alt="" />
          <div
            :class="{
              hover: true,
              'keep-open': openMenuIndex === index,
              selected: checkedItems.indexOf(project._id) > -1,
            }"
          >
            <el-button
              class="btn-edit-chart"
              type="warning"
              icon="el-icon-edit"
              @click="editProject(project)"
              ><strong>EDIT</strong></el-button
            >
            <el-checkbox
              v-if="showCheckbox"
              class="checkbox-item"
              size="medium"
              :border="true"
              :value="project._id"
              :label="project._id"
            ></el-checkbox>

            <el-dropdown
              @command="handleMoreMenu"
              class="btn-more"
              trigger="click"
              @visible-change="moreMenuVisibilityChanged"
              :ref="project._id"
            >
              <el-button
                icon="el-icon-more"
                type="text"
                @click="openDropdown(project, index)"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="rename" icon="el-icon-edit"
                  >Rename</el-dropdown-item
                >
                <el-dropdown-item command="delete" icon="el-icon-delete"
                  >Delete</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="item-bottom">
          <p class="name">{{ project.file_name }}</p>
        </div>
      </div>
    </masonry>
  </el-checkbox-group>
</template>

<script>
import { DASHBOARD_MIXIN } from "@/components/common/mixins/dashboard.mixins";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "projects-grid",
  mixins: [DASHBOARD_MIXIN],
  props: {
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data: function() {
    return {
      openMenuIndex: -1,
      openMenuProject: null,
    };
  },
  computed: {
    ...mapGetters("projectsModule", ["checkedProjects"]),
    ...mapActions("projectsModule", ["changeCheckedProjects"]),
    checkedItems: {
      get() {
        return this.checkedProjects;
      },
      set(value) {
        this.$store.dispatch("projectsModule/changeCheckedProjects", value);
      },
    },
  },
  methods: {
    openDropdown(project, index) {
      if (this.openMenuIndex === index) {
        this.openMenuIndex = -1;
        this.openMenuProject = null;
        this.$refs[project._id][0].hide();
        this.$emit("setOpenMenuIndex", -1);
        return;
      } else {
        this.$refs[project._id][0].show();
      }
      this.$parent.lastOpenMenuIndex = index;
      this.openMenuIndex = index;
      this.openMenuProject = project;
      this.$emit("setOpenMenuIndex", this.openMenuIndex);
    },
    editProject(project) {
      this.$router.push(`/c/${project._id}`);
    },
    handleMoreMenu(command) {
      this.$emit("handleMoreMenu", command);
      this.$emit("projectMenuOpened", this.openMenuProject);
    },
    moreMenuVisibilityChanged(visible) {
      if (!visible && this.$parent.lastOpenMenuIndex === this.openMenuIndex) {
        this.openMenuIndex = -1;
        this.$emit("setOpenMenuIndex", -1);
      }
    },
  },
};
</script>
