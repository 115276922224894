<template>
  <el-dialog
    title="Rename"
    :visible.sync="popupShown"
    :append-to-body="true"
    :before-close="beforeClose"
    width="30%"
  >
    <div class="dialog-rename-project ml3">
      <el-form class="dialog-folder-form" @submit.native.prevent ref="form">
        <el-form-item label="Name">
          <el-input
            placeholder="Name"
            @keyup.enter.native="renameProject"
            v-model="projectNewName"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :icon="$wait.is('savingProject') ? 'el-icon-loading' : ''"
        @click="renameProject"
        >OK</el-button
      >
      <el-button @click="beforeClose">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { DASHBOARD_MIXIN } from "@/components/common/mixins/dashboard.mixins";

export default {
  mixins: [DASHBOARD_MIXIN],
  props: {
    openedMenuProject: {
      required: true,
    },
    folderId: {
      required: true,
    },
  },
  data() {
    return {
      projectNewName: this.openedMenuProject?.file_name,
      popupShown: false,
    };
  },
  computed: {
    ...mapGetters("projectsModule", ["checkedProjects"]),
  },
  mounted: function() {
    this.popupShown = true;
  },
  beforeDestroy: function() {
    this.popupShown = false;
  },
  methods: {
    togglePopupRename(val) {
      this.popupShown = val;
      if (val === false) {
        this.projectNewName = "";
      }
    },
    beforeClose: function() {
      this.$emit("onClosePopupRename");
    },
    renameProject() {
      this.$_dashboardMixin_renameProject(
        this.openedMenuProject._id,
        this.projectNewName
      )
        .then(() => {
          this.togglePopupRename(false);
          this.$_dashboardMixin_getProjects({
            folderId: this.folderId,
          });
        })
        .catch(() => {
          //..
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
